import * as Sentry from "@sentry/react";
import React from "react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

export function initSentry() {
  Sentry.init({
    dsn: "https://52381d42d29e482e87bc80be411d464f@o4506359410917376.ingest.sentry.io/4506359413473280",
    integrations: [
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),  
    ],
  });
}
